import React, { useEffect } from "react";
import Layout from "../../../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import Testimonials from "../../../components/Testimonials";
import Faq from "../../../components/faq";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import ogIMage from "../../../images/og.png";
import Seo from "../../../components/seo";

const LifeAtSedin = ({ location }) => {
    const lifeAtSedin = useStaticQuery(graphql`
        {
            allContentfulFaqs {
                nodes {
                    title
                    description {
                        description
                    }
                    lifeatsedin
                }
            }
            allContentfulTestimonials(filter: { testimonialType: { eq: true } }) {
                nodes {
                    id
                    personDesignation
                    personName
                    profileImage {
                        gatsbyImageData(
                            quality: 100
                            sizes: "250"
                            layout: CONSTRAINED
                            placeholder: BLURRED
                        )
                        title
                    }
                    testimonialType
                    testimonialStatement {
                        testimonialStatement
                    }
                    division {
                        name
                    }
                }
            }
        }
    `);
    const lifeAtSedinFaqs = lifeAtSedin.allContentfulFaqs.nodes.filter((e) => {
        return e.lifeatsedin;
    });
    useEffect(() => {
        if (location.href == "http://localhost:8000/opportunities/life-at-sedin/") {
            document.querySelector(".page-content").style.overflow = "hidden";
        }
    }, []);
    return (
        <Layout>
            <Seo
                title="Life at Sedin"
                description="Sedin is an Indian multinational information technology company that provides global business consulting, digital, IT solutions and outsourcing services. "
                image={ogIMage}
            />
            <section className="life-at-sedin-hero">
                <div className="container container--first-v3">
                    <div className="life-at-sedin-hero__text">
                        <div className="section-tag gap-2x">People. Culture. Entrepreneurship.</div>
                        <div className="text-h1 text-clr-primary text-fw-medium">
                            It's not just about the work.
                        </div>
                    </div>
                    <div className="life-at-sedin-hero__grid">
                        <div className="life-at-sedin-hero__grid-item">
                            <StaticImage
                                src={`../../../images/sedin-life/sedin-life-img.png`}
                                placeholder="blurred"
                                alt="clients"
                                loading="lazy"
                            />
                        </div>
                        <div className="life-at-sedin-hero__grid-item">
                            <StaticImage
                                src={`../../../images/sedin-life/sedin-life-img-2.png`}
                                placeholder="blurred"
                                alt="clients"
                                loading="lazy"
                            />
                        </div>
                        <div className="life-at-sedin-hero__grid-item">
                            <StaticImage
                                src={`../../../images/sedin-life/sedin-life-img-4.png`}
                                placeholder="blurred"
                                alt="clients"
                                loading="lazy"
                            />
                        </div>
                        <div className="life-at-sedin-hero__grid-item ">
                            <StaticImage
                                src={`../../../images/sedin-life/sedin-life-img-3.png`}
                                placeholder="blurred"
                                alt="clients"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="life-at-sedin-message">
                <div className="container container--mid-v2">
                    <div className="life-at-sedin-message__grid">
                        <div className="life-at-sedin-message__grid-title text-h2 text-fw-medium  text-clr-primary">
                            For the people who want to make a difference.
                        </div>
                        <div className="life-at-sedin-message__grid-content  text-clr-secondary text-fw-regular text-p3">
                            <div>
                                Because we have grown into multiple divisions from our
                                entrepreneurial culture, we are able to offer you the opportunity to
                                work in a variety of areas that interest you. We believe in people
                                and their potential, which is why we strive to create an environment
                                where you can thrive as both a professional and an individual.
                            </div>
                            <div>
                                We're a place where you can be yourself, grow in your career and
                                take your ideas to the next level.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="video-section">
                <div className="container container--mid-v2">
                    <InViewLogoAnimationController animationName={"play"}>
                        <Vimeo
                            height="684"
                            video={"https://vimeo.com/722166760"}
                            responsive={true}
                        />
                    </InViewLogoAnimationController>
                </div>
            </section> */}
            <section className="sedin-clients">
                <div className="container container--mid-v2">
                    {/* <ClientsBlock Heading=" Work with top-notch companies" description="" /> */}
                    <div className="tesimonial-content">
                        <Testimonials
                            testimonialData={lifeAtSedin.allContentfulTestimonials.nodes}
                        />
                    </div>
                </div>
            </section>
            <section className="sedin-values">
                <div className="container container--py">
                    <div className="sedin-values__text">
                        <h2 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                            Ethos — 5 things we value
                        </h2>
                        <div className="text-p2 text-clr-secondary text-fw-regular gap-4x">
                            Our values are the foundation of everything we do. They set us apart as
                            a company, and they're what make us who we are.
                        </div>
                    </div>
                    <div className="sedin-values__grid">
                        <div className="sedin-values__grid-item">
                            <div className="sedin-values__grid-item-no text-fw-medium">01</div>
                            <div className="sedin-values__grid-item-value text-p2 text-fw-medium">
                                Trust
                            </div>
                            <div className="sedin-values__grid-item-symbol">
                                <StaticImage
                                    placeholder="blurred"
                                    alt="values"
                                    src={`../../../images/sedin-life/respectful.png`}
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="sedin-values__grid-item">
                            <div className="sedin-values__grid-item-no text-fw-medium">02</div>
                            <div className="sedin-values__grid-item-value text-p2 text-fw-medium">
                                Transparency
                            </div>
                            <div className="sedin-values__grid-item-symbol">
                                <StaticImage
                                    placeholder="blurred"
                                    alt="values"
                                    src={`../../../images/sedin-life/believe.png`}
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="sedin-values__grid-item">
                            <div className="sedin-values__grid-item-no text-fw-medium">03</div>
                            <div className="sedin-values__grid-item-value text-p2 text-fw-medium">
                                Mutual respect
                            </div>
                            <div className="sedin-values__grid-item-symbol">
                                <StaticImage
                                    placeholder="blurred"
                                    alt="values"
                                    src={`../../../images/sedin-life/care.png`}
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="sedin-values__grid-item">
                            <div className="sedin-values__grid-item-no text-fw-medium">04</div>
                            <div className="sedin-values__grid-item-value text-p2 text-fw-medium">
                                Practice empathy
                            </div>
                            <div className="sedin-values__grid-item-symbol">
                                <StaticImage
                                    placeholder="blurred"
                                    alt="values"
                                    src={`../../../images/sedin-life/mistake.png`}
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="sedin-values__grid-item">
                            <div className="sedin-values__grid-item-no text-fw-medium">05</div>
                            <div className="sedin-values__grid-item-value text-p2 text-fw-medium">
                                Pursue passion
                            </div>
                            <div className="sedin-values__grid-item-symbol">
                                <StaticImage
                                    placeholder="blurred"
                                    alt="values"
                                    src={`../../../images/sedin-life/cultivate.png`}
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sedin-benefits">
                <div className="container container--py">
                    <div className="sedin-benefits__text">
                        <h2 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                            More than just a job
                        </h2>
                        <div className="text-p2 text-clr-secondary text-fw-regular gap-4x">
                            Learn more about the perks & benefits we offer to help you reach your
                            best potential.
                        </div>
                    </div>
                    <div className="sedin-benefits__grid">
                        <div className="sedin-benefits__grid-item">
                            <div className="section-count text-h3 text-fw-medium gap-05x">01</div>
                            <div className="text-h3 text-primary-clr text-fw-medium sedin-benefits__grid-item__benefits-title">
                                Flexible hours and remote work
                            </div>
                            <div className="text-p3 text-clr-secondary text-fw-regular">
                                Be a Sedinite from the comfort of your own workspace, and build a
                                schedule that works best for you
                            </div>
                        </div>
                        <div className="sedin-benefits__grid-item">
                            <div className="section-count text-h3 text-fw-medium gap-05x">02</div>
                            <div className="text-h3 text-primary-clr text-fw-medium sedin-benefits__grid-item__benefits-title">
                                Competitive package
                            </div>
                            <div className="text-p3 text-clr-secondary text-fw-regular">
                                Your work is soundly compensated with our competitive pay structure,
                                referral bonuses and benefits
                            </div>
                        </div>
                        <div className="sedin-benefits__grid-item">
                            <div className="section-count text-h3 text-fw-medium gap-05x">03</div>
                            <div className="text-h3 text-primary-clr text-fw-medium sedin-benefits__grid-item__benefits-title">
                                Robust L&D programs
                            </div>
                            <div className="text-p3 text-clr-secondary text-fw-regular">
                                Learn from thought-leaders and highly-skilled colleagues to pave
                                your own path
                            </div>
                        </div>
                        <div className="sedin-benefits__grid-item">
                            <div className="section-count text-h3 text-fw-medium gap-05x">04</div>
                            <div className="text-h3 text-primary-clr text-fw-medium sedin-benefits__grid-item__benefits-title">
                                Multi-domain experience
                            </div>
                            <div className="text-p3 text-clr-secondary text-fw-regular">
                                Explore work across divisions, succeed in a diverse, dynamic and
                                driven ecosystem
                            </div>
                        </div>
                        <div className="sedin-benefits__grid-item">
                            <div className="section-count text-h3 text-fw-medium gap-05x">05</div>
                            <div className="text-h3 text-primary-clr text-fw-medium sedin-benefits__grid-item__benefits-title">
                                Perks and benefits
                            </div>
                            <div className="text-p3 text-clr-secondary text-fw-regular">
                                Enjoy benefits such as life insurance coverages, home office
                                coverages and more
                            </div>
                        </div>
                        <div className="sedin-benefits__grid-item">
                            <div className="section-count text-h3 text-fw-medium gap-05x">06</div>
                            <div className="text-h3 text-primary-clr text-fw-medium sedin-benefits__grid-item__benefits-title">
                                Hackathons and Conferences
                            </div>
                            <div className="text-p3 text-clr-secondary text-fw-regular">
                                Learn from and give back to the community with our multitude of
                                tech-immersive events
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sedin-group">
                <div className="container container--mid-v2">
                    <StaticImage
                        src={`../../../images/sedin-life/team.jpg`}
                        placeholder="blurred"
                        alt="clients"
                        layout="fullWidth"
                        loading="lazy"
                    />
                </div>
            </section>
            <section className="sedin-culture">
                <div className="container container--mid-v2">
                    <div className="sedin-culture__grid">
                        <div className="sedin-culture__grid-item">
                            <StaticImage
                                src={`../../../images/sedin-life/people.png`}
                                placeholder="blurred"
                                alt="clients"
                                layout="fullWidth"
                                loading="lazy"
                            />
                        </div>
                        <div className="sedin-culture__grid-item">
                            <StaticImage
                                src={`../../../images/sedin-life/office.png`}
                                placeholder="blurred"
                                alt="clients"
                                layout="fullWidth"
                                loading="lazy"
                            />
                        </div>
                        <div className="sedin-culture__grid-item">
                            <StaticImage
                                src={`../../../images/sedin-life/people-working.png`}
                                placeholder="blurred"
                                alt="clients"
                                layout="fullWidth"
                                loading="lazy"
                            />
                        </div>
                        <div className="sedin-culture__grid-item">
                            <StaticImage
                                src={`../../../images/sedin-life/office-space.png`}
                                placeholder="blurred"
                                alt="clients"
                                layout="fullWidth"
                                loading="lazy"
                            />
                        </div>
                        <div className="sedin-culture__grid-item">
                            <StaticImage
                                src={`../../../images/sedin-life/brainstorming.png`}
                                placeholder="blurred"
                                alt="clients"
                                layout="fullWidth"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="home-career">
                <div className="container container--mid-v2">
                    <div className="text">
                        <h2 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                            Join us to reshape the technology landscape.
                        </h2>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-4x">
                            We’re on a mission to redefine the global workplace. Explore
                            opportunities across divisions and rub shoulders with skilled
                            professionals from around the world.
                        </p>
                    </div>
                    {/* <div className="career-slider-wrapper">
                        <CareerSlider></CareerSlider>
                    </div> */}
                    <Link
                        className="sdn-cta-btn text-p4 text-fw-medium text-decor-none"
                        target="_blank"
                        to="https://sedintechnologies-talent.freshteam.com/jobs"
                    >
                        View more openings
                    </Link>
                </div>
            </section>
            <section className="sedin-faq">
                <div className="container container--last-v2">
                    <Faq faq={lifeAtSedinFaqs} />
                </div>
            </section>
        </Layout>
    );
};

export default LifeAtSedin;
